var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"listing--detail-header"},[_c('div',{staticClass:"d-flex justify-content-between align-items-start"},[_c('h1',{staticClass:"title--text"},[_vm._v(" "+_vm._s(_vm.listing.listing_title)+" ")]),_c('div',{staticClass:"button--wrapper"},[_c('div',{on:{"click":_vm.toggleShortlist}},[(_vm.shortlisted)?_c('img',{staticClass:"favorite--filled",attrs:{"src":require("@/assets/img/icons/favorite-filled.svg"),"alt":"Favorite"}}):_c('img',{attrs:{"src":require("@/assets/img/icons/favorite.svg"),"alt":"Favorite Outline"}})]),_c('share',{attrs:{"url":''}})],1)]),_c('div',{staticClass:"label--wrapper"},[(_vm.listing.covid_status)?_c('div',{staticClass:"label label--health",class:("health--" + (_vm.listing.covid_status.id)),on:{"click":function($event){return _vm.showCovidProtocol()}}},[_c('img',{attrs:{"src":require("@/assets/img/icons/mask-2.svg")}}),_c('span',[_vm._v(" "+_vm._s(_vm.listing.covid_status.name))])]):_vm._e(),_c('div',{staticClass:"label label--normal"},[_c('img',{attrs:{"src":require("@/assets/img/icons/eye.svg"),"alt":"Viewed Amount"}}),_c('span',[_vm._v(_vm._s(_vm.$t('general.view'))+" "+_vm._s(_vm.listing.view)+" "+_vm._s(_vm.$t('general.times')))])])]),_c('div',{staticClass:"info--wrapper"},[(_vm.bedroom)?_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":require("@/assets/img/icons/bed.svg"),"alt":"Bedroom"}}),_c('span',[_vm._v(" "+_vm._s(_vm.bedroom)+" "+_vm._s(_vm.$t('general.bedroom'))+" ")])]):_vm._e(),(_vm.bathroom)?_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":require("@/assets/img/icons/shower.svg"),"alt":"Bathroom"}}),_c('span',[_vm._v(" "+_vm._s(_vm.bathroom)+" "+_vm._s(_vm.$t('general.bathroom'))+" ")])]):_vm._e(),(
        _vm.listing.property.property_type_id &&
          _vm.listing.property.property_type_id !== 12 &&
          _vm.listing.size_in_square_meters
      )?_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":require("@/assets/img/icons/property-size.svg"),"alt":"Property Size"}}),_c('span',[_vm._v(" "+_vm._s(_vm.listing.type === 'R' ? _vm.$t('addListing.areaSize') : _vm.$t('addListing.buildingSize'))+" "+_vm._s(_vm.listing.size_in_square_meters)+" sqft ")])]):_vm._e(),(
        _vm.listing.property.property_type_id &&
          _vm.listing.property.property_type_id !== 12 &&
          _vm.listing.type === 'S' &&
          _vm.listing.land_size
      )?_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":require("@/assets/img/icons/property-size.svg"),"alt":"Land Size"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('addListing.landSize'))+" "+_vm._s(_vm.listing.land_size)+" sqft ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }