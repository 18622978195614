<template>
  <div
    class="listing--detail-attachment"
    v-show="listing.youtube_url || (galleries && galleries.length > 0)"
  >
    <div class="attachment" v-if="listing.youtube_url" @click="$modal.show('youtube-modal')">
      <img
        class="attachment--bg"
        :src="`//img.youtube.com/vi/${youtubeId}/0.jpg`"
        :alt="`Youtube Thumbnail ${listing.listing_title} - Propfix`"
      />
      <div class="attachment--bg-overlay"></div>
      <div class="attachment--content">Video</div>
    </div>
    <div
      class="attachment"
      v-if="galleries && galleries.length > 0 && galleries[0]"
      @click="clickImage(0)"
    >
      <picture>
        <source :srcset="galleries[0].url" type="image/webp" />
        <source :srcset="getFallbackImage(galleries[0].url)" type="image/jpeg" />
        <img
          :src="getFallbackImage(galleries[0].url)"
          class="attachment--bg"
          :alt="`Floor Plan ${listing.listing_title} - Propfix`"
        />
      </picture>
      <div class="attachment--bg-overlay"></div>
      <div class="attachment--content">Floor Plans</div>
    </div>
    <client-only>
      <vue-gallery-slideshow
        :images="galleries"
        :index="imageIndex"
        @close="clickImage(null)"
      ></vue-gallery-slideshow>
    </client-only>
  </div>
</template>

<script>
const VueGallerySlideshow = () => import('vue-gallery-slideshow');
import { mapState, mapGetters } from 'vuex';
import HelperMixin from '@/mixins/helpers';

export default {
  name: 'listing-detail-attachment',
  mixins: [HelperMixin],
  components: {
    VueGallerySlideshow,
  },
  data() {
    return {
      youtubeEmbedUrl: null,
      youtubeId: null,
    };
  },
  props: ['listing'],
  methods: {
    clickImage(index) {
      this.$store.commit('v2/listing/set_floor_plan_index', index);
    },
  },
  computed: {
    ...mapState({
      imageIndex: state => state.v2.listing.floorPlanIndex,
      baseUrl: state => state.global.baseUrl,
      isLoading: state => state.global.isLoading,
    }),
    ...mapGetters({
      galleries: 'v2/listing/floorPlanGalleries',
    }),
  },
  async mounted() {
    this.youtubeEmbedUrl = await this.$store.dispatch(
      'v2/listing/getYoutubeEmbedUrl',
      this.listing.youtube_url,
    );
    this.youtubeId = await this.$store.dispatch(
      'v2/listing/getYoutubeId',
      this.listing.youtube_url,
    );
  },
  beforeDestroy() {
    this.$store.commit('v2/listing/set_floor_plan_index', null);
  },
};
</script>
