<template>
  <section class="listing--detail-facility" v-show="isShow">
    <h2 class="title--text">
      {{ $t('addListing.facility') }}
    </h2>
    <div
      class="facility--container"
      :class="{ 'hide--all': !seeAll && listing.facilities.length > 2 }"
    >
      <div class="overlay"></div>
      <div
        class="facility--item"
        v-for="(facility, k) in listing.facilities"
        :key="`facility-${k}`"
      >
        <div class="d-flex align-items-center">
          <i class="icon icon--md" :class="facility.icon"> </i>
          <div class="facility--text">
            <h3>{{ facility.name }}</h3>
            <span v-show="facility.amount !== 0">{{ facility.amount }}</span>
            <span v-show="facility.amount !== 0"> {{ facility.unit }}</span>
          </div>
        </div>
      </div>
      <div class="mt-4" style="width: 100%" v-if="validOtherFacilities">
        <h2 class="title--text" v-if="validFacilities">
          {{ $t('addListing.otherFacility') }}
        </h2>
        <ul>
          <li v-for="(otherFacility, k) in listing.other_facilities" :key="`other-facility-${k}`">
            {{ otherFacility.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="see--all" @click="switchSeeAll()" v-if="listing.facilities.length > 2">
      <span v-show="seeAll === false">{{ $t('general.showMore') }}</span>
      <span v-show="seeAll === true">{{ $t('general.showLess') }}</span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'listing-detail-facility',
  props: ['listing'],
  data: () => ({
    seeAll: false,
  }),
  computed: {
    validFacilities() {
      return this.listing.facilities && this.listing.facilities.length > 0;
    },
    validOtherFacilities() {
      return this.listing.other_facilities && this.listing.other_facilities.length > 0;
    },
    isShow() {
      return this.validFacilities || this.validOtherFacilities;
    },
  },
  methods: {
    switchSeeAll() {
      this.seeAll = !this.seeAll;
    },
  },
};
</script>
