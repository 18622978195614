<template>
  <section class="listing--detail-detail">
    <h2 class="title--text">
      {{ $t('addListing.propDetail') }}
    </h2>
    <div class="column--wrapper">
      <div class="column--content">
        <h3 class="column--title">
          {{ $t('general.propertyType') }}
        </h3>
        <div class="column--desc">
          {{ listing.property.property_type_name }}
        </div>
      </div>
      <div class="column--content" v-if="listing.done_year_est">
        <h3 class="column--title">
          {{ listing.is_done ? $t('general.builtAt') : $t('general.estimatedCompletionYear') }}
        </h3>
        <div class="column--desc">
          {{ listing.done_year_est }}
        </div>
      </div>
      <div
        class="column--content"
        v-else-if="listing.is_done !== undefined && listing.is_done !== null"
      >
        <h3 class="column--title">
          {{ $t('general.status') }}
        </h3>
        <div class="column--desc">
          {{ listing.is_done ? $t('general.ready') : $t('general.notReady') }}
        </div>
      </div>
      <div class="column--content" v-if="listing.available_from">
        <h3 class="column--title">
          {{ $t('general.availableFrom') }}
        </h3>
        <div class="column--desc">
          {{ $date.format(listing.available_from, 'dd MMMM yyyy') }}
        </div>
      </div>
      <div class="column--content" v-if="listing.tenure_type">
        <h3 class="column--title">
          {{ $t('general.filter.tenure') }}
        </h3>
        <div class="column--desc">
          {{ listing.tenure_type }}
        </div>
      </div>
      <div class="column--content" v-if="listing.property.postal_code">
        <h3 class="column--title">
          {{ $t('general.postalCode') }}
        </h3>
        <div class="column--desc">
          {{ listing.property.postal_code }}
        </div>
      </div>
      <div class="column--content" v-if="listing.furnishing_condition">
        <h3 class="column--title">
          {{ $t('general.furnishingCondition') }}
        </h3>
        <div class="column--desc">
          {{ listing.furnishing_condition }}
        </div>
      </div>
      <div class="column--content">
        <h3 class="column--title">
          {{ $t('general.unitName') }}
        </h3>
        <div class="column--desc">
          {{ listing.unit_name ? listing.unit_name : 'N/A' }}
        </div>
      </div>
      <div class="column--content">
        <h3 class="column--title">
          {{ $t('general.totalFloor') }}
        </h3>
        <div class="column--desc">
          {{ listing.total_number_of_floor ? listing.total_number_of_floor : '-' }}
        </div>
      </div>
      <div class="column--content" v-if="listing.floor">
        <h3 class="column--title">
          {{ listing.type === 'R' ? $t('general.floorRent') : $t('general.floorSell') }}
        </h3>
        <div class="column--desc">
          {{ listing.floor ? listing.floor : '-' }}
        </div>
      </div>
      <div
        class="column--content"
        v-if="listing.property.property_type_id && listing.property.property_type_id !== 12"
      >
        <h3 class="column--title">
          <span v-if="listing.type === 'R'">{{ $t('general.areaSize') }}</span>
          <span v-else-if="listing.type === 'S'">{{ $t('general.buildingSize') }}</span>
        </h3>
        <div class="column--desc">{{ listing.size_in_square_meters }} sqft</div>
      </div>
      <div class="column--content" v-if="listing.sell_price && listing.size_in_square_meters">
        <div class="column--title">
          PSF
        </div>
        <div class="column--desc">
          {{ generatePSF(listing.sell_price, listing.size_in_square_meters) }} psf
        </div>
      </div>
      <div class="column--content" v-if="listing.type === 'R' && listing.rent_type">
        <h3 class="column--title">
          {{ $t('addListing.rentType') }}
        </h3>
        <div class="column--desc">
          {{ listing.rent_type.name }}
        </div>
      </div>
      <div class="column--content" v-if="listing.unit">
        <h3 class="column--title">
          {{ $t('general.unit') }}
        </h3>
        <div class="column--desc">
          {{ listing.unit }}
        </div>
      </div>
      <div class="column--content" v-if="listing.min_rent_duration">
        <div class="column--title">
          {{ $t('general.minRentDuration') }}
        </div>
        <div class="column--desc">
          {{ `${listing.min_rent_duration} ${$t('date.month.title')}(s)` }}
        </div>
      </div>
      <div class="column--content" v-if="listing.booking_fee_month_amount">
        <div class="column--title">
          {{ $t('general.bookingFee') }}
        </div>
        <div class="column--desc">
          {{
            `${listing.booking_fee_month_amount} ${$t('date.month.title')}(s) ${$t(
              'general.ofRentPrice',
            )}`
          }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'listing-detail-detail',
  props: ['listing'],
  methods: {
    generatePSF(sellPrice, sizeInSquareFeet) {
      if (sellPrice && sizeInSquareFeet) {
        return (
          'S$' +
          (sellPrice / sizeInSquareFeet).toLocaleString('en-SG', { maximumFractionDigits: 2 })
        );
      } else {
        return null;
      }
    },
  },
};
</script>
