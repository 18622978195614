<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    class="modal--request-book"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('general.requestToBook') }}
      </div>
      <section class="modal--container">
        <div>
          <div class="form--group" :class="{ 'has-error': validation.hasError('moveDate') }">
            <div class="left--col">
              <label for="move-date-picker">{{ $t('general.startFrom') }}</label>
            </div>
            <div class="right--col">
              <date-picker
                id="move-date-picker"
                v-model="moveDate"
                :minDate="new Date()"
                :disabledDates="disabledDates"
                format="dd/MM/yyyy"
                class="request--book-date-picker"
              />
              <span class="val-error mt-2" v-if="validation.hasError('moveDate')">{{
                validation.firstError('moveDate')
              }}</span>
            </div>
          </div>
          <div class="form--group">
            <div class="left--col">
              <label for="select-rent-period">{{ $t('general.rentPeriod2') }}</label>
            </div>
            <div class="right--col">
              <v-select
                id="select-rent-period"
                :label="$t('general.chooseRentPeriod')"
                class="dropdown left basic--dropdown"
                v-model="selectedPeriod"
                solo
                hide-details
                return-object
                :items="rentPeriodOptions"
                item-text="name"
                item-value="id"
              ></v-select>
            </div>
          </div>
          <div class="form--group" v-if="selectedPeriod" v-show="selectedPeriod.id == 5">
            <div class="left--col">
              <label for="req-start-time">{{ $t('general.startHour') }}</label>
            </div>
            <div class="right--col">
              <multiselect
                id="req-start-time"
                v-model="startTime"
                name="openTime"
                :options="openTimeOptions"
                :searchable="false"
                :close-on-select="true"
                :show-labels="true"
                :custom-label="timeLabel"
                :allow-empty="false"
                :placeholder="$t('booking.hourBook.placeholder')"
              />
            </div>
          </div>
          <div class="form--group">
            <div class="left--col">
              <label for="req-duration">{{ $t('general.filter.duration') }}</label>
            </div>
            <div class="right--col">
              <div class="d-flex switch flex-wrap">
                <div
                  class="btn mb-2"
                  :class="localDuration === 12 ? 'btn-primary' : 'btn-primary--outline'"
                  @click="chooseDuration(12)"
                  v-if="minRentDuration <= 12"
                >
                  {{ `12 ${selectedPeriod ? selectedPeriod.name : ''}s` }}
                </div>
                <div
                  class="btn mb-2"
                  :class="localDuration === 24 ? 'btn-primary' : 'btn-primary--outline'"
                  @click="chooseDuration(24)"
                  v-if="minRentDuration <= 24"
                >
                  {{ `24 ${selectedPeriod ? selectedPeriod.name : ''}s` }}
                </div>
                <div
                  class="btn mb-2"
                  :class="localDuration === 36 ? 'btn-primary' : 'btn-primary--outline'"
                  @click="chooseDuration(36)"
                  v-if="minRentDuration <= 36"
                >
                  {{ `36 ${selectedPeriod ? selectedPeriod.name : ''}s` }}
                </div>
              </div>
              <div class="input--group d-flex">
                <div class="input--group-operator mr-2">
                  <button
                    class="btn btn-primary"
                    :disabled="localDuration <= minRentDuration ? 'disabled' : false"
                    type="button"
                    @click="subDuration"
                  >
                    -
                  </button>
                </div>
                <client-only>
                  <cleave
                    id="req-duration"
                    :class="validation.hasError('localDuration') ? 'is-invalid' : ''"
                    class="basic--input text-center"
                    :options="durationCleaveOption"
                    type="text"
                    v-model.number="localDuration"
                  ></cleave>
                </client-only>
                <div class="input--group-operator ml-2">
                  <button
                    class="btn btn-primary"
                    :disabled="localDuration >= 50 ? 'disabled' : false"
                    type="button"
                    @click="addDuration"
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="val-error mt-2" v-if="validation.hasError('localDuration')">
                {{ validation.firstError('localDuration') }}
              </div>
              <div class="mt-2" v-if="selectedPeriod">{{ selectedPeriod.name }}</div>
            </div>
          </div>
        </div>
        <hr class="separator" v-if="listing && !listing.view_to_offer" />
        <div
          class="duration--wrapper"
          v-if="listing && !listing.view_to_offer && priceDurationDetail"
        >
          <div class="title--row">
            <div class="column--1 title--text">{{ $t('general.rentPeriod2') }}</div>
            <div class="column--2 title--text">{{ $t('general.filter.duration') }}</div>
            <div class="column--3 title--text">{{ $t('general.pricePer') }}</div>
            <div class="column--4 title--text">{{ $t('general.totalPrice') }}</div>
          </div>
          <div
            v-for="priceDetail in priceDurationDetail"
            :key="`${priceDetail.period_name}-${priceDetail.total_price}`"
          >
            <div class="content--row" v-if="priceDetail.duration > 0">
              <!--              <div class="custom-control custom-checkbox column&#45;&#45;1">-->
              <!--                            <input-->
              <!--                              type="checkbox"-->
              <!--                              class="custom-control-input"-->
              <!--                              :disabled="true"-->
              <!--                              :checked="true"-->
              <!--                            />-->
              <!--                <label class="custom-control-label">-->
              <!--                  {{ priceDetail.period_name }}-->
              <!--                </label>-->
              <!--              </div>-->
              <div class="column--1">{{ priceDetail.period_name }}</div>
              <div class="column--2">{{ priceDetail.duration }}</div>
              <div class="column--3">{{ priceDetail.price }}</div>
              <div class="column--4">
                {{ priceDetail.total_price ? priceDetail.total_price : 'S$ 0' }}
              </div>
            </div>
          </div>
        </div>
        <div class="overview" v-if="listing && !listing.view_to_offer">
          <div class="top--part">
            <div>{{ $t('general.filter.duration') }}</div>
            <div>{{ lamaSewa ? lamaSewa : '' }}</div>
          </div>
          <div class="bottom--part">
            <div>{{ $t('general.subPrice') }}</div>
            <div>{{ total_rent ? $n(total_rent, 'currency', 'en-SG') : '' }}</div>
          </div>
        </div>
        <div class="btn btn-primary main--btn" @click="openRequestToBookPage()">
          {{ $t('addListing.btn.continue') }}
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import LodashMixin from '@/mixins/lodash';
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';
const DatePicker = () => import('@/components/utils/date-picker');
import { Validator } from 'simple-vue-validator';
const Cleave = () => import('vue-cleave-component');

import Multiselect from 'vue-multiselect';

require('vue-multiselect/dist/vue-multiselect.min.css');
export default {
  mixins: [LodashMixin, HelperMixin],
  data() {
    return {
      modalName: 'modal-request-to-book',
      durationCleaveOption: {
        numeral: true,
        numeralIntegerScale: 2,
        numeralPositiveOnly: true,
      },
      openTimeOptions: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
      ],
      minRentDuration: 1,
    };
  },
  components: {
    DatePicker,
    Cleave,
    Multiselect,
  },
  created() {
    this.getTotalRentDebounce = this.debounce(this.getTotalRent, 500);
  },
  computed: {
    ...mapState({
      listing: state => state.v2.listing.listing,
      total_rent: state => state.v2.rentTransaction.initiate.pricing.totalRent,
      harga_sewa: state => state.v2.rentTransaction.initiate.pricing.hargaSewa,
      discount: state => state.v2.rentTransaction.initiate.pricing.discount,
      discount_price: state => state.v2.rentTransaction.initiate.pricing.discountPrice,
      lamaSewa: state => state.v2.rentTransaction.initiate.pricing.lamaSewa,
      endDateName: state => state.v2.rentTransaction.initiate.pricing.endDateName,
      priceDurationDetail: state => state.v2.rentTransaction.initiate.pricing.priceDurationDetail,
      moveDateName: state => state.v2.rentTransaction.initiate.pricing.moveDateName,
      currentPhone: state => (state.global.user ? state.global.user.phone : null),
      chosenPromo: state => state.v2.rentTransaction.initiate.promo.chosenPromo,
      promoWarning: state => state.v2.rentTransaction.initiate.promo.warningMessage,
    }),
    rentPeriodOptions() {
      if (!this.listing) return [];
      if (this.listing.type === 'S') return [];
      if (this.listing.view_to_offer) {
        return [
          {
            id: 2,
            name: 'Month',
          },
        ];
      } else {
        return this.listing.rent_prices.map(rentPrice => {
          return {
            id: rentPrice.periode_id,
            name: rentPrice.periode,
          };
        });
      }
    },
    startTime: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.startTime;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_START_TIME', val);
      },
    },
    localDuration: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.localDuration;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_LOCAL_DURATION', val);
      },
    },
    selectedPeriod: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.selectedPeriod;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_SELECTED_PERIOD', val);
      },
    },
    disabledDates: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.disabledDates;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_DISABLE_DATES', val);
      },
    },
    moveDate: {
      get() {
        return this.$store.state.v2.rentTransaction.initiate.moveDate;
      },
      set(val) {
        this.$store.commit('v2/rentTransaction/initiate/SET_MOVE_DATE', val);
      },
    },
    firstRentPrice() {
      if (this.listing) {
        return this.listing.rent_prices[0];
      } else {
        return null;
      }
    },
  },
  watch: {
    async localDuration(value) {
      if (value) {
        await this.getTotalRentDebounce();
      }
    },
    async selectedPeriod(value) {
      if (value) {
        this.localDuration = 1;
        await this.getTotalRentDebounce();
      }
    },
    async moveDate(value) {
      if (value) {
        await this.getTotalRentDebounce();
      }
    },
  },
  validators: {
    moveDate(value) {
      if (value === undefined) value = null;
      return Validator.value(value).required(this.$i18n.t('errors.booking.moveDate.required'));
    },
    localDuration(value) {
      if (value === undefined) value = null;
      let period = '';
      if (this.selectedPeriod) period = this.selectedPeriod.name + '(s)';
      return Validator.value(value)
        .required(this.$i18n.t('errors.booking.duration.required'))
        .greaterThan(
          this.minRentDuration - 1,
          this.$i18n.t('errors.booking.duration.min', {
            min: this.minRentDuration,
            period: period,
          }),
        );
    },
  },
  methods: {
    chooseDuration(duration) {
      this.localDuration = duration;
    },
    async beforeOpen(event) {
      if (!event.params) {
        this.$store.dispatch('v2/rentTransaction/initiate/promo/restoreInitialState');
        this.$store.dispatch('v2/rentTransaction/initiate/restoreInitialState');

        if (this.currentPhone) this.localPhone = this.currentPhone;

        this.loading = true;
        this.moveDate = null;

        if (this.listing) {
          let selectedPeriodId = this.listing.view_to_offer ? 2 : this.firstRentPrice.periode_id;
          this.selectedPeriod = this.rentPeriodOptions.find(obj => {
            return obj.id === selectedPeriodId;
          });

          let response = await this.$store.dispatch('v2/rentTransaction/initiate/getBlockedDate', {
            uuid: this.listing.uuid,
          });

          if (response && response.type === 'success') {
            if (response.data != null && response.data.length > 0) {
              // console.log('MASUK INI');
              let blocked_date = response.data.map(item => {
                return this.$date.parse(item, 'MM/dd/yyyy');
              });
              this.disabledDates = blocked_date;
              // this.move_date = this.$date.add(this.$date.max(blocked_date), { days: 1 });
              this.$forceUpdate();
            } else {
              // this.moveDate = new Date();
              this.disabledDates = [];
            }
          } else {
            this.disabledDates = [];
          }

          if (this.listing.min_rent_duration) {
            this.minRentDuration = this.listing.min_rent_duration;
            this.localDuration = this.listing.min_rent_duration;
          }
        }
        this.loading = false;
      }
      await this.getTotalRent();
    },
    async subDuration() {
      if (this.localDuration > 0) {
        this.localDuration--;
      } else {
        this.localDuration = 1;
      }
    },
    async addDuration() {
      this.localDuration++;
    },
    getTotalRentDebounce() {},
    timeLabel(listingOperational) {
      return listingOperational + '.00';
    },
    async getTotalRent() {
      if (this.selectedPeriod) {
        let moveTime = null;
        if (this.selectedPeriod.id == 5) {
          moveTime = this.timeLabel(this.startTime);
        }

        // console.log('sebelum SINI GAYS', this.moveDate);
        await this.$store.dispatch('v2/rentTransaction/initiate/pricing/getTotalRent', {
          periode_id: this.selectedPeriod.id,
          move_date: this.$date.format(this.moveDate, 'yyyy-MM-dd'),
          move_time: moveTime,
          duration: this.localDuration,
          uuid: this.listing.uuid,
          promo_uuid: this.chosenPromo?.uuid,
        });
        // console.log('sesudah SINI GAYS');
      } else {
        console.log('WARNING: YOU MUST CHOOSE A RENT PERIOD FIRST BEFORE CALLING getTotalRent!');
      }
    },

    close() {
      this.$modal.hide('modal-request-to-book');
    },
    async openRequestToBookPage() {
      // this.close();
      const isValid = await this.$validate();
      if (isValid) {
        // eslint-disable-next-line no-undef
        // fbq('track', 'requestToBook');
        const dateUnix = this.$date.getUnixTime(this.moveDate);
        // const date = this.$date.fromUnixTime(dateUnix);
        // const dateFormatted = this.$date.format(date, 'dd-MM-yyyy');
        // console.log('asd 0', this.moveDate);
        // console.log('asd 1', dateUnix);
        // console.log('asd 2', date);
        // console.log('asd 3', dateFormatted);

        const query = {
          date: dateUnix,
          duration: this.localDuration,
          rpid: this.selectedPeriod.id,
          lid: this.listing.uuid,
          time: this.timeLabel(this.startTime),
        };
        this.$router.push({ path: '/request-to-book', query });
      }
    },
  },
};
</script>
