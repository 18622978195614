<template>
  <section class="listing--detail-area" v-if="validateTrainStations || validateAmenities">
    <h2 class="title--text">
      {{ $t('general.areaDetail') }}
    </h2>
    <v-tabs center-active>
      <v-tab :href="`#tab-1`" v-if="validateTrainStations">
        {{ $t(`general.trains`) }}
      </v-tab>
      <v-tab :href="`#tab-2`" v-if="validateAmenities">
        {{ $t(`general.otherAmenities`) }}
      </v-tab>
      <v-tab-item :value="`tab-1`" v-if="validateTrainStations">
        <div class="d-flex flex-wrap">
          <div
            class="col-12 col-md-4 mb-3"
            v-for="(amenity, k) in listing.nearest_amenities.train_stations"
            :key="`train-station-${k}`"
          >
            <div class="d-flex justify-content-between mb-2">
              <span class="station--name mr-2">{{ amenity.station_name }}</span>
              <span class="station--distance">{{ amenity.distance }}</span>
            </div>
            <div class="d-flex flex-wrap" v-if="amenity.lines && amenity.lines.length > 0">
              <div
                class="line--code col-auto btn mr-2 mb-2"
                :style="`color: #ffffff; background-color: ${line.hex_color}`"
                v-for="(line, k) in amenity.lines"
                :key="`line-${k}`"
              >
                {{ line.line_code }}
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item :value="`tab-2`" v-if="validateAmenities">
        <ul class="list-amenities">
          <li v-for="(amenity, k) in listing.amenities" :key="`other-amenity-${k}`">
            {{ amenity.name }}
          </li>
        </ul>
      </v-tab-item>
    </v-tabs>
  </section>
</template>

<script>
export default {
  name: 'listing-detail-detail',
  props: ['listing'],
  components: {},
  computed: {
    validateTrainStations() {
      return (
        this.listing.nearest_amenities &&
        this.listing.nearest_amenities.train_stations &&
        this.listing.nearest_amenities.train_stations.length > 0
      );
    },
    validateAmenities() {
      return this.listing.amenities && this.listing.amenities.length > 0;
    },
  },
};
</script>
