<template>
  <section class="listing--detail-action">
    <div class="contact--label">
      <img
        :src="listing.owner.photo_profile_user"
        :alt="listing.owner.fullname"
        width="50"
        height="50"
      />
      <router-link :to="listing.links.owner">
        <span>{{ listing.owner.fullname }}</span>
      </router-link>
    </div>
    <div v-if="listing.type === 'R'">
      <div class="price--container">
        <div class="left--col">
          {{ $t('addListing.rentPrice') }}
        </div>
        <div class="right--col">
          <div v-if="!listing.view_to_offer">
            <div class="price--item" v-for="rent_price in listing.rent_prices" :key="rent_price.id">
              <div
                class="price--before"
                v-if="listing.discount_percentage || listing.discount_price"
              >
                {{ $n(rent_price.price, 'currency', 'en-SG') }} /{{ rent_price.periode }}
              </div>
              <div class="price--after">
                {{
                  rent_price.price
                    ? $n(
                        getPriceAfterDiscount(
                          listing.discount_percentage,
                          listing.discount_price,
                          rent_price.price,
                        ).price_after_discount,
                        'currency',
                        'en-SG',
                      )
                    : 'S$ 0'
                }}
                /{{ rent_price.periode }}
              </div>
            </div>
          </div>
          <div v-else class="price--item">
            <div class="price--after">
              View To Offer
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="price--container">
        <div class="left--col">
          {{ $t('addListing.sellPrice') }}
        </div>
        <div class="right--col">
          <div v-if="!listing.view_to_offer">
            <div class="price--item">
              <div
                class="price--before"
                v-if="listing.discount_percentage || listing.discount_price"
              >
                {{ $n(listing.sell_price, 'currency', 'en-SG') }}
              </div>
              <div class="price--after">
                {{
                  listing.sell_price
                    ? $n(
                        getPriceAfterDiscount(
                          listing.discount_percentage,
                          listing.discount_price,
                          listing.sell_price,
                        ).price_after_discount,
                        'currency',
                        'en-SG',
                      )
                    : 'S$ 0'
                }}
              </div>
            </div>
          </div>
          <div v-else class="price--item">
            <div class="price--after">
              View To Offer
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="price&#45;&#45;container" v-if="!listing.free_reservation_fee">-->
      <!--        <div class="left&#45;&#45;col">-->
      <!--          {{ $t('general.reservationFee') }}-->
      <!--        </div>-->
      <!--        <div class="right&#45;&#45;col price&#45;&#45;simple">-->
      <!--          {{ $n(500000, 'currency', 'en-SG') }}-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="price--container" v-if="listing.done_year_est">
        <div class="left--col">
          {{ listing.is_done ? $t('general.builtAt') : $t('general.estimatedCompletionYear') }}
        </div>
        <div class="right--col price--simple">
          {{ listing.done_year_est }}
        </div>
      </div>
      <div class="price--container" v-else>
        <div class="left--col">
          {{ $t('general.status') }}
        </div>
        <div class="right--col price--simple">
          {{ $t('general.ready') }}
        </div>
      </div>
    </div>
    <div
      role="alert"
      class="alert alert-warning mt-2"
      v-if="listing.occupied"
      style="text-align: center"
    >
      {{ listing.type === 'R' ? $t('addListing.rentedS') : $t('addListing.soldS') }}
    </div>
    <div v-if="!isOwner">
      <div
        role="alert"
        class="alert alert-warning mt-2"
        v-if="!listing.isActive"
        style="text-align: center"
      >
        {{ $t('general.inactiveListing') }}
      </div>
      <div v-else-if="!isOwner">
        <!--        <div v-if="listing && listing.type === 'R'">-->
        <!--          <div class="btn btn-primary action&#45;&#45;button" @click="requestToBookHandler">-->
        <!--            {{ $t('general.requestToBook') }}-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <reserve-listing-->
        <!--          v-else-if="listing && listing.type === 'S'"-->
        <!--          :full="true"-->
        <!--          :listing="listing"-->
        <!--        />-->
        <!--        <hr />-->
        <div>
          <a
            href="javascript:"
            @click="handleContact($t('general.contactUs'))"
            style="text-decoration: none;"
          >
            <div class="btn btn--whatsapp action--button">
              <img src="@/assets/img/icons/whatsapp-1.svg" alt="Whatsapp" />
              <span>{{ $t('general.contactUs') }}</span>
            </div>
          </a>
        </div>
        <div>
          <a
            href="javascript:"
            @click="handleContact($t('general.callNow'))"
            style="text-decoration: none;"
          >
            <div class="btn btn-primary action--button">
              <img src="@/assets/img/icons/phone-white.svg" alt="Call" />
              <span>{{ $t('general.callNow') }}</span>
            </div>
          </a>
        </div>
      </div>
      <div class="terms">
        {{ $t('general.tos1') }}
        <router-link to="/term-of-service">{{ $t('general.tos2') }}</router-link>
        {{ $t('general.and') }}
        <router-link to="/privacy-policy">{{ $t('general.tos3') }} </router-link>
        .
      </div>
    </div>
    <owner-section
      v-if="loggedIn && isOwner"
      class="owner--section"
      :listing="listing"
      :checkBankAccountDone="checkBankAccountDone"
    />
  </section>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

// const ReserveListing = () => import('@/components/listing-detail/reserve-listing');
const OwnerSection = () => import('@/components/listing-detail/owner-section');

export default {
  name: 'listing-detail-action',
  props: ['listing'],
  mixins: [HelperMixin],
  components: {
    // ReserveListing,
    OwnerSection,
  },
  data() {
    return {
      checkBankAccountDone: false,
    };
  },
  computed: {
    ...mapState({
      loggedIn: state => state.global.loggedIn,
    }),
    isOwner() {
      if (this.$store.state.global.user != null && this.listing.owner != null) {
        return this.$store.state.global.user.id == this.listing.owner.id;
      }
      return false;
    },
  },
  methods: {
    handleContact(title) {
      this.$modal.show('modal--contact-us', { listing: this.listing, title: title });
    },
    requestToBookHandler() {
      if (this.loggedIn) {
        this.$modal.show('modal-request-to-book');
      } else {
        this.$modal.show('modal-login');
      }
    },
  },
};
</script>
