<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div v-if="listing">
      <banner :listing="listing"></banner>
      <div class="listing--container">
        <div class="left--col">
          <listing-header :listing="listing" />
          <attachment :listing="listing" />
          <detail :listing="listing" />
          <area-detail :listing="listing" />
          <panorama :listing="listing" />
          <description :description="listing.property.about_property" />
          <operational-hour :listing="listing" />
          <facility :listing="listing"></facility>
          <amenity :amenities="listing.amenities" />
          <listing-map :property="listing.property" />
          <mortgage-calculator v-if="listing.type === 'S'" :listing="listing"></mortgage-calculator>
        </div>
        <div class="right--col">
          <action :listing="listing" />
          <!--        <contact :listing="listing" />-->
        </div>
      </div>
      <similar :listing="listing" />
      <youtube-modal :youtubeUrl="listing.youtube_url" />
    </div>
    <client-only>
      <modal-request-to-book />
      <modal-request-to-book-success />
      <modal-contact-us />
      <!--      <modal-request-installment />-->
    </client-only>
    <script v-html="jsonldBreadcrumb" type="application/ld+json"></script>
    <script v-html="jsonld" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/listing-detail/banner';
import ListingHeader from '@/components/listing-detail/header';
import OperationalHour from '@/components/listing-detail/operational-hour';
import Attachment from '@/components/listing-detail/attachment';
import Panorama from '@/components/listing-detail/panorama';
import Detail from '@/components/listing-detail/detail';
import AreaDetail from '@/components/listing-detail/area-detail';
import Description from '@/components/listing-detail/description';
import Facility from '@/components/listing-detail/facility';
import Amenity from '@/components/listing-detail/amenity';
import ListingMap from '@/components/listing-detail/map';
import Action from '@/components/listing-detail/action';
// import Contact from '@/components/listing-detail/contact';
import Similar from '@/components/listing-detail/similar';
import YoutubeModal from '@/components/listing-detail/youtube-modal';
const MortgageCalculator = () => import('@/components/listing-detail/mortgage-calculator');

import DefaultHeadMixin from '@/mixins/defaultHead';
import HelperMixin from '@/mixins/helpers';
import { mapGetters, mapState } from 'vuex';

import ModalContactUs from '@/components/listing-detail/modals/contact-us';
import ModalRequestToBook from '@/components/request-to-book/modals/request-to-book-modal';
// import ModalRequestInstallment from '@/components/installment/modals/request-installment-modal';
import ModalRequestToBookSuccess from '@/components/request-to-book/modals/request-to-book-success-modal';
export default {
  mixins: [DefaultHeadMixin, HelperMixin],
  async fetch({ store, params, error, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    try {
      await store.dispatch('v2/listing/getListing', params.hashId);
    } catch (e) {
      error(new Error(e.message), 404);
    }

    const listing = store.state.v2.listing.listing;
    const baseUrl = store.state.global.baseUrl;

    // const listingType = listing.type === 'R' ? 'for Rent' : 'for Sale';
    // const listingType2 = listing.type === 'R' ? 'Rent' : 'Buy';
    // const propertyType = listing.property.property_type_name
    //   ? listing.property.property_type_name
    //   : 'Property';
    const listingTitle = listing.listing_title;
    const aboutProperty = listing.property.about_property
      ? ' | ' + listing.property.about_property
      : '';
    const ogImage =
      listing.primary_photo && Object.keys(listing.primary_photo).length > 0
        ? listing.primary_photo.url.replace('/listing', '/listing/mobile')
        : '';
    const canonical = baseUrl + (listing.links ? listing.links.detail : '');
    const ownerName = listing.owner.fullname;
    let addOn = ' | ';
    if (ownerName) addOn += `${ownerName} - `;
    addOn += 'Propfix';

    // let title = propertyType + ' ' + listingType + ' in ' + listingTitle;
    let title = listingTitle;
    // if (title.length > 52) title = title.substring(0, 49) + '...';

    // let description = listingType2 + ' ' + propertyType + ' in ' + listingTitle + aboutProperty;
    let description = listingTitle + '. ' + aboutProperty;
    if (description.length > 307) description = description.substring(0, 304) + '...';

    const head = {
      title: title + addOn,
      meta: [
        {
          hid: 'og-url',
          property: 'og:url',
          content: baseUrl + (listing.links ? listing.links.detail : ''),
        },
        { hid: 'og-type', property: 'og:type', content: 'article' },
        { hid: 'og-title', property: 'og:title', content: title + addOn },
        {
          hid: 'og-description',
          property: 'og:description',
          content: description + addOn,
        },
        {
          hid: 'description',
          name: 'description',
          content: description + addOn,
        },
        { hid: 'og-site-name', property: 'og:site_name', content: 'Propfix SG' },
        {
          hid: 'og-image',
          property: 'og:image',
          content: ogImage,
        },
        { hid: 'og-image-width', property: 'og:image:width', content: '450' },
        { hid: 'og-image-height', property: 'og:image:height', content: '450' },
      ],
      link: [
        {
          rel: 'canonical',
          href: canonical,
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  computed: {
    ...mapState({
      listing: state => state.v2.listing.listing,
      isLoading: state => state.global.isLoading,
      isLoggedIn: state => state.global.loggedIn,
    }),
    ...mapGetters({
      galleries: 'v2/listing/galleries',
    }),
    typeString() {
      if (this.listing) {
        return this.listing.type === 'R' ? this.$t('general.rent') : this.$t('general.sell');
      } else {
        return '';
      }
    },
    typeUrl() {
      if (this.listing) {
        return this.listing.type === 'R' ? 'rent' : 'sell';
      } else {
        return '';
      }
    },
    propertyNameUrl() {
      if (this.listing && this.listing.property) {
        return `?property_type_id=%255B${this.listing.property.property_type_id}%255D`;
      } else {
        return '';
      }
    },
    breadcrumbs() {
      if (!this.listing) return [];
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: `${this.typeString}`,
          to: `/${this.typeUrl}/search/all/`,
        },
        {
          text: `${this.listing.property.property_type_name}`,
          to: `/${this.typeUrl}/search${this.propertyNameUrl}`,
        },
        {
          text: this.listing.listing_title,
          to: this.listing.links ? this.listing.links.detail : this.$router.currentRoute.path,
        },
      ];
    },
    jsonldBreadcrumb() {
      if (!this.listing) return {};
      let baseUrl = this.$store.state.global.baseUrl;
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: this.$t('breadcrumb.homepage'),
            item: baseUrl + '/',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: `${this.typeString}`,
            item: baseUrl + `/${this.typeUrl}/search/all/`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: `${this.listing.property.property_type_name}`,
            item: baseUrl + `/${this.typeUrl}/search${this.propertyNameUrl}`,
          },
          {
            '@type': 'ListItem',
            position: 4,
            name: this.listing.listing_title,
            item: this.listing.links
              ? baseUrl + this.listing.links.detail
              : baseUrl + this.$router.currentRoute.path,
          },
        ],
      };
    },
    jsonld() {
      if (!this.listing) return {};
      return this.getListingJsonld({
        baseUrl: this.$store.state.global.baseUrl,
        path:
          this.listing && this.listing.links
            ? this.listing.links.detail
            : this.$router.currentRoute.path,
        listing: this.listing,
        galleries: this.galleries,
      });
    },
  },
  mounted() {
    // this.$modal.show('youtube-modal');
  },
  components: {
    ModalRequestToBook,
    // ModalRequestInstallment,
    ModalContactUs,
    ModalRequestToBookSuccess,
    DefaultLayout,
    YoutubeModal,
    OperationalHour,
    Banner,
    ListingHeader,
    Attachment,
    Panorama,
    Detail,
    AreaDetail,
    Description,
    Facility,
    Amenity,
    ListingMap,
    Action,
    // Contact,
    Similar,
    MortgageCalculator,
  },
};
</script>
