<template>
  <section class="listing--detail-amenity" v-show="amenities && amenities.length !== 0">
    <div class="title--text">
      {{ $t('general.amenity') }}
    </div>
    <ul class="amenity--wrapper">
      <li v-for="(amenity, index) in amenities" :key="index">
        {{ amenity.name }}
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'listing-detail-amenity',
  props: ['amenities'],
  data: () => ({
    list: [
      'Premium Grade A building [CENTENNIAL TOWER 35th Floor] that is located along Jl gatot Subroto',
      'Near the Semanggi Interchange',
      'It has back entrance that connects to kuningan CBD and Jl Sudirman',
      'Surrounded by hotels and malls',
      'Near Busway (Gatot Subroto Jamsostek) & MRT (Istora Mandiri/Benhil) access, Highway entrance',
    ],
  }),
};
</script>
