<template>
  <VueSlickCarousel
    class="carousel--wrapper listing--slick"
    v-bind="settings"
    @beforeChange="beforeChange"
    @afterChange="afterChange"
  >
    <div v-for="(content, index) in contents" :key="index" class="carousel--slide">
      <!--      <card-project-partner-->
      <!--        v-if="category === 'projectpartner'"-->
      <!--        :projectPartner="content"-->
      <!--        :allowMultiImage="false"-->
      <!--      />-->
      <!--      <card-listing :premier="premier" v-else :listing="content" :allowMultiImage="false" />-->
      <card-listing :premier="premier" :listing="content" :allowMultiImage="false" />
    </div>
    <template #prevArrow>
      <div class="custom-prev--arrow listings-prev--arrow"></div>
    </template>
    <template #nextArrow>
      <div class="custom-next--arrow listings-next--arrow"></div>
    </template>
  </VueSlickCarousel>
</template>

<script>
const CardListing = () => import('@/components/utils/card-listing');
// const CardProjectPartner = () => import('@/components/utils/card-project-partner');

export default {
  components: {
    // CardProjectPartner,
    CardListing,
  },
  props: {
    contents: {
      default: [],
      require: true,
    },
    category: {
      default: null,
    },
    premier: {
      default: false,
    },
  },
  data: () => ({
    dragging: false,
    settings: {
      dots: false,
      edgeFriction: 0.35,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesPerRow: 4,
      autoplay: true,
      autoplaySpeed: 5000,
      // lazyLoad: 'progressive', // Ini kalo barengan multiple rows jadi gak bisa slide
      draggable: false,
      responsive: [
        {
          breakpoint: 1365,
          settings: {
            slidesPerRow: 4,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesPerRow: 2,
          },
        },
      ],
    },
  }),
  methods: {
    beforeChange() {
      this.dragging = true;
    },
    afterChange() {
      this.dragging = false;
    },
    clickHandler(e) {
      if (this.dragging) {
        e.preventDefault();
      }
    },
  },
};
</script>
