<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    class="modal--request-book-success"
    name="modal-request-to-book-success"
  >
    <div class="modal--wrapper">
      <section class="modal--container">
        <img class="main--image" src="/img/placeholder.png" />
        <div class="title--text">
          Selamat! Pengajuan Anda berhasil
        </div>
        <div class="date--time">
          Kamis, 11 Feb 2021 | 15:56
        </div>
        <div class="content--row">
          <div>{{ $t('profile.name') }}</div>
          <div>John Doe</div>
        </div>
        <div class="content--row">
          <div>{{ $t('general.startFrom') }}</div>
          <div>29 Feb 2021</div>
        </div>
        <div class="content--row">
          <div>{{ $t('general.until') }}</div>
          <div>29 Mar 2021</div>
        </div>
        <div class="content--row">
          <div>Total Biaya Sewa</div>
          <div>S$ 5.962.000</div>
        </div>
        <div class="btn btn-primary main--btn" @click="close">
          Lihat Booking Saya
        </div>
      </section>
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
export default {
  methods: {
    close() {
      this.$modal.hide('modal-request-to-book-success');
    },
  },
};
</script>
