<template>
  <modal
    :name="modalName"
    adaptive
    :width="500"
    @before-open="beforeOpen"
    height="auto"
    class="modal--contact-us"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" alt="Back" />
        </div>
        {{ title }}
      </div>
      <section class="modal--container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ title }}
        </div>
        <div class="wrapper--main" v-if="step === 1">
          <div class="px-2 pb-2">
            {{ $t('contact.modal.description') }}
          </div>
          <form @submit.prevent="submit">
            <div class="add-space">
              <div
                class="form--group d-block"
                :class="{ 'has-error': validation.hasError('name') }"
              >
                <input
                  class="basic--input"
                  id="name"
                  type="text"
                  v-model="name"
                  placeholder="Name"
                />
                <div class="val-error mt-2" v-if="validation.hasError('name')">
                  {{ validation.firstError('name') }}
                </div>
              </div>
              <div
                class="form--group d-block"
                :class="{ 'has-error': validation.hasError('email') }"
              >
                <input
                  v-model="email"
                  type="email"
                  id="email"
                  class="basic--input"
                  placeholder="Email"
                />
                <div class="val-error mt-2" v-if="validation.hasError('email')">
                  {{ validation.firstError('email') }}
                </div>
              </div>
              <div
                class="form--group d-block"
                :class="{ 'has-error': validation.hasError('phone') }"
              >
                <client-only>
                  <vue-tel-input
                    id="phone"
                    :value="phone ? phone : ''"
                    @input="phoneInput"
                    :validCharactersOnly="true"
                    placeholder="Phone Number"
                    defaultCountry="sg"
                  />
                </client-only>
                <!--              <cleave-->
                <!--                id="phone"-->
                <!--                v-model="phone"-->
                <!--                :options="phoneCleaveOption"-->
                <!--                class="form-control"-->
                <!--                type="text"-->
                <!--                placeholder="Phone Number"-->
                <!--              />-->
                <div class="val-error mt-2" v-if="validation.hasError('phone')">
                  {{ validation.firstError('phone') }}
                </div>
              </div>
            </div>
            <div class="row desc-card justify-content-center">
              <div class="col-6">
                <button class="btn btn--primary btn-block btn-primary--outline" type="submit">
                  {{ $t('contact.modal.continue') }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="wrapper--main" v-if="step === 2">
          <div class="success--title">
            {{ $t('contact.modal.successTitle') }}
          </div>
          <div class="success--subtitle">{{ $t('contact.modal.contactInfo') }}:</div>
          <div class="contact--number">
            {{ contactNumber }}
          </div>
          <div class="d-flex justify-content-center mt-2 button--wrapper">
            <a
              :href="`tel:${contactNumber}`"
              class="btn btn-primary u--align-center justify-content-center"
            >
              <i class="ion-android-call mr-2"></i>
              {{ $t('contact.modal.call') }}
            </a>
            <a
              class="btn btn--whatsapp u--align-center justify-content-center ml-4"
              :href="`https://wa.me/${contactNumber}?text=${textMessage}`"
              target="_blank"
            >
              <i class="ion-social-whatsapp-outline mr-2"></i>
              WhatsApp
            </a>
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { Validator } from 'simple-vue-validator';

import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import Cookies from 'js-cookie';
import { mapState } from 'vuex';

export default {
  components: {
    VueTelInput,
  },
  data: () => ({
    modalName: 'modal--contact-us',
    loading: false,
    title: '',
    listing: null,
    step: 1,
    contactNumber: null,
  }),
  computed: {
    ...mapState({
      isLoggedIn: state => state.global.loggedIn,
      user: state => state.global.user,
    }),
    name: {
      get() {
        return this.$store.state.v2.contact.name;
      },
      set(val) {
        this.$store.commit('v2/contact/SET_NAME', val);
      },
    },
    email: {
      get() {
        return this.$store.state.v2.contact.email;
      },
      set(val) {
        this.$store.commit('v2/contact/SET_EMAIL', val);
      },
    },
    phoneCountryCode: {
      get() {
        return this.$store.state.v2.contact.phoneCountryCode;
      },
      set(val) {
        this.$store.commit('v2/contact/SET_PHONE_COUNTRY_CODE', val);
      },
    },
    phone: {
      get() {
        return this.$store.state.v2.contact.phone;
      },
      set(val) {
        this.$store.commit('v2/contact/SET_PHONE', val);
      },
    },
    textMessage() {
      return this.listing
        ? encodeURIComponent(
            `Hello, I want more information regarding this listing: ${this.listing.listing_title}`,
          ) + `%0a${this.listing.links.full_url}`
        : '';
    },
  },
  methods: {
    phoneInput(phone, phoneObject) {
      if (phoneObject && phoneObject.countryCode) {
        this.phoneCountryCode = phoneObject.countryCode;
      } else {
        this.phoneCountryCode = null;
      }
      this.$store.commit('v2/contact/SET_PHONE', phone);
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      this.step = 1;
      this.$store.commit('v2/contact/RESTORE_DEFAULT_VALUE');

      this.title = event.params.title ? event.params.title : this.$t('contact.modal.title');
      this.listing = event.params.listing;
      this.contactNumber = this.listing && this.listing.owner ? this.listing.owner.phone : null;

      if (!this.isLoggedIn) {
        const cookieStr = document.cookie;
        const cookies = require('cookie').parse(cookieStr || '') || {};
        if (cookies.contactData) {
          let contactData = JSON.parse(cookies.contactData);
          console.log('CONTACT DATA: ', contactData);
          this.name = contactData.name;
          this.email = contactData.email;
          this.phone = contactData.phone;
        }
      } else {
        console.log('CONTACT DATA: ', this.user);
        this.name = this.user.fullname;
        this.email = this.user.email;
        this.phone = this.user.phone;
      }
    },
    async submit() {
      const isValid = await this.$validate();
      if (isValid) {
        console.log('KIRIM INFO BUAT CONTACT US: ', {
          name: this.name,
          email: this.email,
          phone: this.phone,
        });

        try {
          let data = await this.$store.dispatch('v2/contact/sendContactRequest', this.listing.uuid);
          console.log('HASIL REQUEST CONTACT: ', data);
          if (data.type === 'success') {
            this.step = 2;
            if (!this.isLoggedIn) {
              Cookies.set(
                'contactData',
                {
                  name: this.name,
                  phone: this.phone,
                  email: this.email,
                },
                { expires: 10 },
              );
            }
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
    },
  },
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.name.required'));
    },
    email(value) {
      return Validator.value(value).email(this.$i18n.t('errors.loginRegister.email.invalid'));
    },
    phone(value) {
      return Validator.value(value).required(this.$i18n.t('errors.phone.required'));
    },
  },
};
</script>
